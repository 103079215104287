<template>
  <div>
    <div id="content" class="col-md-12">
      <div class="products-category">
        <!-- <h3 class="title-category">{{ proName }}</h3> -->
        <!-- <div class="category-desc">
          <div class="row">
            <div class="col-sm-12">
              <div id="bannersection" class="banners animated-background">
                {{ imageBanner }}
                <div>
                  <a href="#" v-if="imageBanner != ''">
                    <img
                      :src="imageBanner"
                      alt="img cate"
                      style="width: 1800px; height: auto"
                    />
                    <br />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Filters -->
        <div
          id="product-filter-top"
          class="product-filter product-filter-top filters-panel"
        >
          <div class="row">
            <!-- Top Filters Start -->
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 top-filters-block"
            >
              <fieldset>
                <ul class="checkboxes_list">
                  <li>
                    <input
                      type="checkbox"
                      v-model="topFilterParams"
                      checked=""
                      name="manufacturer"
                      id="manufacturer_1"
                      value="sale-items"
                    />
                    <label for="manufacturer_1">
                      <span class="btn btn-sm sale-badge">Sale</span>
                      View sale items only
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      v-model="topFilterParams"
                      name="manufacturer"
                      id="manufacturer_2"
                      value="free-shipping"
                    />

                    <label for="manufacturer_2"> Free Shipping</label>
                  </li>
                </ul>
              </fieldset>
            </div>
            <!-- Top Filters End -->

            <!-- Sorty By Start -->
            <!-- <div
              id="sortbtn-block"
              class="col-lg-8 col-md-8 col-sm-10 col-xs-10 sortbtn-block"
            >
              <label>Sort by</label>
              <button type="button" class="sort-type-btn btn btn-default">
                Best Match
              </button>
              <button type="button" class="sort-type-btn btn btn-default">
                Newest
              </button>
              <button
                type="button"
                class="sort-type-btn sort-price-btn btn btn-default"
              >
                <div class="sort-price-btn-inner">
                  <div class="sort-price-left-col">Price</div>
                  <div class="sort-price-right-col">
                    <div class="sort-one">
                      <a
                        ><i
                          class="fa fa-chevron-up sort-one-arrow"
                          aria-hidden="true"
                        ></i
                      ></a>
                    </div>
                    <div class="sort-two">
                      <a
                        ><i
                          class="fa fa-chevron-down sort-two-arrow"
                          aria-hidden="true"
                        ></i
                      ></a>
                    </div>
                  </div>
                </div>
              </button>
            </div> -->
            <!-- Sorty By End -->

            <div class="col-lg-4 col-md-4 col-sm-2 col-xs-2 view-mode" style="display: none">
              <div class="list-view">
                <button
                  class="btn btn-default grid"
                  @click.prevent.stop="display('grid')"
                  :class="showmode == 'grid' ? 'active' : ''"
                  data-view="grid"
                  data-toggle="tooltip"
                  data-original-title="Grid"
                >
                  <i>
                    <img
                      width="14px"
                      style="margin-bottom: 2px"
                      src="/assets/image/ic_grid.png"
                      alt
                    />
                  </i>
                </button>
                <button
                  class="btn btn-default list"
                  @click.prevent.stop="display('list')"
                  :class="showmode == 'list' ? 'active' : ''"
                  data-view="list"
                  data-toggle="tooltip"
                  data-original-title="List"
                >
                  <i>
                    <img
                      width="14px"
                      style="margin-bottom: 2px"
                      src="/assets/image/ic_list.png"
                      alt
                    />
                  </i>
                </button>
              </div>
            </div>
            <!-- <div
              class="short-by-show form-inline text-right col-md-7 col-sm-9 col-xs-12"
            >
              <div class="form-group short-by">
                <label class="control-label" for="input-sort">Sort By:</label>
                <select
                  id="input-sort"
                  class="form-control select-arrow"
                  v-model="selectedsortStatus"
                  @change="sortProducts()"
                >
                  <option
                    v-for="(item, index) in filterStatusArray"
                    :key="index"
                    :value="item.value"
                    selected="selected"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div> -->
            <!-- <div class="form-group">
                <label class="control-label" for="input-limit">Show:</label>
                <select
                  id="input-limit"
                  class="form-control select-arrow"
                  v-model="selectedProductCount"
                  @change="changeProductCount()"
                >
                  <option
                    v-for="(item, index) in filterProductCount"
                    :key="index"
                    :value="item.value"
                    selected="selected"
                  >{{item.text}}</option>
                </select>
              </div>-->
            <!-- </div> -->
            <!-- <div class="box-pagination col-md-3 col-sm-4 col-xs-12 text-right">
                                <ul class="pagination">
                                    <li class="active"><span>1</span></li>
                                    <li><a href="">2</a></li><li><a href="">&gt;</a></li>
                                    <li><a href="">&gt;|</a></li>
                                </ul>
            </div>-->
          </div>
        </div>
        <!-- //end Filters -->

        <!--changed listings-->

        <div class="products-list row nopadding-xs so-filter-gird">
          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sortbtn-blocksk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="!blockLoading && blocks.length === 0">
            <h4>No Items Found</h4>
          </div>

          <div v-for="(item, index) in blocks" :key="index">
            <component
              :is="item.blockdatacomponent"
              v-bind:noslide="true"
              v-bind:data="item"
            ></component>
          </div>
          <div class="row">
          </div>
        </div>
        <!--// End Changed listings-->
        <!-- Filters -->
        <div class="product-filter product-filter-bottom filters-panel">
          <div class="row">
            <div class="col-sm-6 text-left"></div>
            <div class="col-sm-6 text-right">
              <b-pagination
                v-model="currentPage"
                ref="paginationCurrentPage"
                :per-page="pageSize"
                :total-rows="rowcount"
                align="right"
              ></b-pagination>
            </div>
          </div>
        </div>
        <!-- //end Filters -->
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="productModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
            <h5 class="modal-title" id="productModalLongTitle">
              Product Quick View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top: -20px"
            >
              <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductModalContent
              v-bind:pid="this.product_id"
              v-bind:count="this.uniqcount"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductTile from "@/components/product/product_tile_catogary.vue";
import ProductTileBrand from "@/components/product/product_tile_brand.vue";
import ProductTileGrid from "@/components/product/product_tile_grid.vue";

import ProductModalContent from "@/components/product/product_modal.vue";
import mixinCommon from "@/mixins/APICommon";
import mixinProduct from "@/mixins/buyer/APIProduct";
import { async } from "q";

export default {
  components: {
    ProductTile,
    ProductTileBrand,
    ProductTileGrid,
    ProductModalContent,
  },
  mixins: [mixinProduct, mixinCommon],
  data() {
    return {
      image: "",
      breadcrumb: [
        {
          text: "Product List",
          href: "/productlist",
          active: false,
        },
      ],
      filterStatusArray: [
        { value: "", text: "Default" },
        { value: "0", text: "Product Name (A - Z)" },
        { value: "1", text: "Product Name (Z - A)" },
        { value: "2", text: "Price (Low - High)" },
        { value: "3", text: "Price (High - Low)" },
        { value: "4", text: "Rating (High - Low)" },
        { value: "5", text: "Rating (Low - High)" },
        // ,{ value:"6", text: "Model (A - Z)" }
        // ,{ value:"7", text: "Model (Z - A)" }
      ],
      selectedsortStatus: "",
      filterProductCount: [
        { value: "15", text: "15" },
        { value: "25", text: "25" },
        { value: "50", text: "50" },
        { value: "75", text: "75" },
        { value: "100", text: "100" },
      ],
      selectedProductCount: "15",
      proName: "",
      cID: "",
      product_id: "",
      blocks: [],
      tableItems: [],
      showmode: "list",
      storeListData: [],
      blockLoading: true,
      wishlit: false,
      conCurrentpage: "",
      busy: false,
      productFullCount: null,
      pagicount: 1,
      pageSize: 12,
      rowcount: 0,
      currentPage: 1,
      conditionCp: 1,
      stoppagi: false,
      uniqcount: 0,
      mainCategory: [],
      imageCategory: "",
      topFilterParams: [],
    };
  },
  computed: {
    catID() {
      let url = window.location.href.split("id=");
      this.cID = url[1];
      return url[1];
    },
    imageBanner() {
      if (this.imageCategory == "" || this.imageCategory == undefined) {
        return "";
      } else {
        return this.imageCategory;
      }
    },
  },
  watch: {
    cID() {
      this.getCategories();
    },
    currentPage: function (val) {
      if (val) {
        this.pagination(val);
      }
    },
    $route(to, from) {
      this.$forceUpdate();
      this.callFucntionsByOrder();
    },
    "$route.query.id"() {
      this.$forceUpdate();
      this.callFucntionsByOrder();
      this.getProductsByID();
    },
    "$store.state.filterdata": function () {
      this.blocks = [];
      this.getProductsByID();
    },
    topFilterParams : function () {
      this.getProductsByID();
    }
  },
  mounted: function () {
    // Sort button events
    // this.$nextTick(function () {
    //   const sortBtnBlock = document.querySelector("#sortbtn-block");
    //   var sortBtns = sortBtnBlock.querySelectorAll(".sort-type-btn");
    //   var priceTextInner = document.querySelector(".sort-price-left-col");
    //   var sortOneArrow = document.querySelector(".sort-one-arrow");
    //   var sortTwoArrow = document.querySelector(".sort-two-arrow");

    //   sortBtns[0].style.borderColor = "#5779ae";
    //   sortBtns[0].style.color = "#5779ae";

    //   sortBtns.forEach((btnEl) => {
    //     btnEl.addEventListener("click", function (e) {
    //       sortBtns.forEach((el) => {
    //         el.style.borderColor = "#000000";
    //         el.style.color = "#000000";
    //         priceTextInner.style = "#000000";
    //         sortOneArrow.style = "#000000";
    //         sortTwoArrow.style = "#000000";
    //       });
    //       e.target.style.borderColor = "#5779ae";
    //       e.target.style.color = "#5779ae";

    //       if (
    //         e.target.classList.contains("sort-price-left-col") ||
    //         e.target.classList.contains("sort-price-right-col") ||
    //         e.target.classList.contains("sort-one") ||
    //         e.target.classList.contains("sort-two")
    //       ) {
    //         e.target.style.borderColor = "#5779ae";
    //         e.target.style.color = "#5779ae";
    //       }
    //     });
    //   });
    // });
    this.handleBreadcrumb();
    this.display("grid");
  },
  created() {
    this.initiateList();
    this.callFucntionsByOrder();
    this.getProductsByID();
  },
  methods: {
    getCategories: async function () {
      try {
        this.imageCategory = "";
        let response = await this.getMainCategories();

        this.mainCategory = response[0].sub_category_one;
      } catch (error) {
        throw error;
      }
    },
    getURLcID() {
      let url = window.location.href.split("id=");
      this.cID = url[1];
      return url[1];
    },
    callFucntionsByOrder: async function () {
      localStorage.setItem("filterdata", null);

      this.$store.dispatch("fetchFilterData");
      this.getURLcID();
      this.blocks = []; //clear for load new category products
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    addToWishList: async function (item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 1 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Added to Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        this.selectedProduct.wishlit = true;
        this.setProductInfo();
      } catch (error) {
        throw error;
      }
    },
    removeFromWishList: async function (item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        this.selectedProduct.wishlit = false;
        this.setProductInfo();
      } catch (error) {
        throw error;
      }
    },
    getProductsByID: async function () {
      this.blocks = [];
      this.storeListData = [];
      this.blockLoading = true;
      try {
        let params = "category=" + this.cID;
        params += "&page=" + this.currentPage;
        params += "&perpage=" + this.pageSize;
        if (this.$store.state.filterdata != null) {
          if (this.$store.state.filterdata.brands.length > 0) {
            for (
              let index = 0;
              index < this.$store.state.filterdata.brands.length;
              index++
            ) {
              params += "&brands=" + this.$store.state.filterdata.brands[index];
            }
          }
          if (this.$store.state.filterdata.attributes.length > 0) {
            for (
              let index = 0;
              index < this.$store.state.filterdata.attributes.length;
              index++
            ) {
              params +=
                "&attribute_values=" +
                this.$store.state.filterdata.attributes[index];
            }
          }
          if (this.$store.state.filterdata.searchtext != "") {
            params += "&name=" + this.$store.state.filterdata.searchtext;
          }
        }
        this.storeListData = await this.getProductListByCIDFilters(params);
        let filterarray = [];
        if (this.topFilterParams.length > 0) {
           this.topFilterParams.forEach((element) => {
              if (element === "sale-items") {
                filterarray = this.storeListData.filter(e => e.simple_products[0].special_price < e.simple_products[0].price);
              } else if(element === "free-shipping") {
                filterarray = this.storeListData.filter(e => e.is_free_shipping == true);
              }
            });
          
        }
        if (this.topFilterParams.length > 0) {
          this.blocks = [];
          this.storeListData = filterarray;
        } else {
          this.blocks = [];
        }

        let productlist = this.storeListData;
        if (productlist.length !== 0) {
          this.proName = "";
          if (localStorage.categoryName) {
            this.proName = localStorage.getItem("categoryName");
          }

        } else if (productlist.length === 0) {
          if (this.$store.state.category_name === null) {
            this.proName = "";
            this.proName = localStorage.getItem("categoryName");
          } else {
            this.proName = "";
            this.proName = this.$store.state.category_name;
          }
        }
        this.image =
          productlist[0].category.image != undefined
            ? productlist[0].category.image
            : "";
        let blockdata = [];
        for (let index = 0; index < productlist.length; index++) {
          let imgs = [];
          let imagearray = productlist[index].show_image;
          for (let index2 = 0; index2 < imagearray.length; index2++) {
            let imgpath = imagearray[index2].image;
            // let is1 = imgpath.split("upload/")[0];
            // let is4 = imgpath.split("upload/")[1];
            // let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
            // let is3 = is4.split("/")[1];
            // imgpath = is1 + is2 + is4;
            imgs.push(imgpath);
          }
          if (productlist[index].rate.toString().includes(".")) {
            let value1 = productlist[index].rate.toString().split(".");
            productlist[index].rateAvg = value1[0];
            if (value1[1] === "5" || value1[1] > "5") {
              productlist[index].rateAvg =
                parseInt(productlist[index].rateAvg) + 1;
            }
          } else {
            productlist[index].rateAvg = productlist[index].rate;
          }
          let oldprice = "";
          let newprice = "";
          if (
            productlist[index].simple_products[0].special_price != 0 &&
            productlist[index].simple_products[0].special_price != ""
          ) {
            newprice = productlist[index].simple_products[0].special_price;
            oldprice = productlist[index].simple_products[0].price;
          } else {
            newprice = productlist[index].simple_products[0].price;
            oldprice = "";
          }

          let discounpre = "";

          if (oldprice != "") {
            discounpre = ((oldprice - newprice) / oldprice) * 100;
            discounpre = Math.round(discounpre);
          }

          blockdata.push({
            blockdatacomponent: "ProductTile",
            discountprecentage: discounpre,
            imgpath: imgs,
            item_id: productlist[index]._id,
            prediscount: oldprice,
            price: newprice,
            product_name: productlist[index].product_name,
            primary_product: productlist[index].primary_product,
            model: productlist[index].model,
            rating: productlist[index].rateAvg,
            title: productlist[index].product_name,
            wishlit: productlist[index].wishlit,
            store: productlist[index].store,
            url: "/product/" + productlist[index]._id,
          });
        }

        // this.blocks = [];
        // blockdata.forEach((element) => {
        //   this.blocks.push(element);
        // });
        this.blocks = blockdata;
        let res1 = await this.getProductCountByCIDFilters(params);
        this.rowcount = res1.count;
        this.blockLoading = false;

        // this.blocks = this.blocks;
        // if (this.blocks.length === datacount) {
        //   this.blockLoading = false;
        // } else {
        //   this.stoppagi = true;
        // }
      } catch (error) {
        this.blockLoading = false;
      }
    },
    pagination: function (val) {
      this.currentPage = val
      this.getProductsByID();
    },
    sortProducts() {
      if (this.selectedsortStatus === "") {
        location.reload();
      }
      if (this.selectedsortStatus === "0") {
        this.blocks.sort(function (a, b) {
          var x = a.product_name.toLowerCase();
          var y = b.product_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }
      if (this.selectedsortStatus === "1") {
        this.blocks.sort(function (a, b) {
          var x = a.product_name.toLowerCase();
          var y = b.product_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "2") {
        this.blocks.sort(function (a, b) {
          return a.price - b.price;
        });
      }
      if (this.selectedsortStatus === "3") {
        this.blocks.sort(function (a, b) {
          return a.price - b.price;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "4") {
        this.blocks.sort(function (a, b) {
          return a.rating - b.rating;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "5") {
        this.blocks.sort(function (a, b) {
          return a.rating - b.rating;
        });
      }
      if (this.selectedsortStatus === "6") {
        this.blocks.sort(function (a, b) {
          var x = a.model.toLowerCase();
          var y = b.model.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }
      if (this.selectedsortStatus === "7") {
        this.blocks.sort(function (a, b) {
          var x = a.model.toLowerCase();
          var y = b.model.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        this.blocks.reverse();
      }
      getProductsByID();
    },
    initiateList() {
      let self = this;
      $(".list-view .btn").each(function () {
        $(this).siblings(".btn").removeClass("active");
      });
    },
    display(view) {
      $(".products-list").removeClass("list grid").addClass(view);
      $(".list-view .btn").removeClass("active");
      if (view == "list") {
        //$('.products-list .product-layout').addClass('col-lg-12');
        // $('.products-list .product-layout .left-block').addClass('col-md-4');
        // $('.products-list .product-layout .right-block').addClass('col-md-8');
        $(".products-list .product-layout .item-desc").removeClass("hidden");
        $(".products-list .product-layout .list-block").removeClass("hidden");
        $(".products-list .product-layout .button-group").addClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "list");
      } else {
        //$('.products-list .product-layout').removeClass('col-lg-12');
        //$('.products-list .product-layout .left-block').removeClass('col-md-4');
        //$('.products-list .product-layout .right-block').removeClass('col-md-8');
        $(".products-list .product-layout .item-desc").addClass("hidden");
        $(".products-list .product-layout .list-block").addClass("hidden");
        $(".products-list .product-layout .button-group").removeClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "grid");
      }

      this.showmode = view;
    },
    pushToProduct() {
      this.$router.push("/product");
    },
    parentModalTrigger(pid) {
      this.uniqcount = this.uniqcount + 1;
      this.product_id = pid;

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };

      //    this.setProductInfo();
      $("#productModalCenter").modal("show");
    },
    // loadMore: async function(currentpage) {
    //   console.log("loadMore log");
    //   console.log(this.stoppagi);
    //   if (!this.stoppagi) {
    //     this.busy = true;

    //     await this.getProductsByID(this.pagicount);

    //     this.pagicount++;
    //     this.busy = false;
    //   }
    // },
    // changeProductCount() {
    //   console.log("changeProductCount log");
    //   this.blocks = [];
    //   this.stoppagi = false;
    //   this.busy = false;
    //   this.pagicount = 2;
    //   document.getElementById("infiniteScrollLoader").style.display = "block";
    //   this.getProductsByID(1);
    // }
  },
};
</script>
<style>
#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.m0p0 {
  margin: 0px !important;
  padding: 0px !important;
}
.select-arrow {
  -webkit-appearance: menulist !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  white-space: pre !important;
  -webkit-rtl-ordering: logical !important;
  color: #555 !important;
  background-color: white !important;
  cursor: default !important;
  border-width: 1px !important;
  border-style: solid !important;
  -o-border-image: initial !important;
  border-image: initial !important;
}

.filters-panel .list-view button.btn.active {
  background: #5779ae !important;
}

.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid #5779ae;
  background: #5779ae none repeat scroll 0% 0%;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}

/* ASHEN LOADING ANIMATION PLACE HOLDER */
.placeholder {
  margin: 0 auto;
  max-width: 200px;
  min-height: 100px;
  background-color: #eee;
}

/* Styles added on 05-10-2020 */
#product-filter-top .view-mode {
  text-align: right;
  padding-top: 15px;
}

/* Top filter styles start */
.top-filters-block {
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-filters-block ul li {
  float: left;
  margin-right: 25px;
}
.top-filters-block ul li .sale-badge {
  min-height: 30px;
  line-height: 30px;
  border-radius: 4px;
  background: #ffd338;
  margin-right: 8px;
  text-transform: uppercase;
  font-weight: bold;
}
.top-filters-block .checkboxes_list input[type="checkbox"] + label {
  padding-left: 40px;
  line-height: 32px;
}

.top-filters-block .checkboxes_list input[type="checkbox"] + label::before {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

/* Sort button styles start */
.sortbtn-block {
  padding-top: 15px;
  padding-bottom: 15px;
}
.sortbtn-block button {
  margin-right: 10px;
}
.sortbtn-block .btn {
  border-radius: 4px;
}
.sortbtn-block .btn:hover {
  background: none;
  color: #5779ae;
}
.sortbtn-block .btn:focus {
  outline: none;
  box-shadow: none;
}
.sortbtn-block label {
  margin-right: 15px;
}
.sortbtn-block .sort-price-btn {
  min-width: 100px;
}
.sortbtn-block .sort-price-btn-inner {
  display: flex;
  flex-wrap: wrap;
}
.sortbtn-block .sort-price-btn-inner .sort-price-left-col {
  flex: 2;
  line-height: 32px;
}
.sortbtn-block .sort-price-btn-inner .sort-price-right-col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  line-height: auto;
}
.sortbtn-block .sort-price-btn-inner .sort-price-right-col .fa {
  font-size: 7px;
}
.sortbtn-block .sort-price-btn-inner .sort-price-right-col .sort-one {
  display: flex;
  justify-content: center;
  flex: 1;
  line-height: 7px;
  align-items: flex-end;
  padding-bottom: 4px;
  /* outline: 1px solid red; */
}
.sortbtn-block .sort-price-btn-inner .sort-price-right-col .sort-two {
  display: flex;
  justify-content: center;
  flex: 1;
  line-height: 0px;
  align-items: flex-start;
  padding-top: 4px;
  /* outline: 1px solid red; */
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  /* background-size: 800px 104px; */
  /* min-height: 500px; */
  position: relative;
}

@media screen and (max-width: 630px) {
  #product-filter-top .view-mode {
    width: 100%;
  }
  .sortbtn-block {
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
  .sortbtn-block .btn {
    margin-bottom: 15px;
  }
}
</style>